import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { Image } from '../lib/components/image';
import { Container } from '../lib/components/layout/container';
import { Grid } from '../lib/components/layout/grid';
import { Section } from '../lib/components/layout/section';
import { AnchorLink } from '../lib/components/navigation/anchor-link';
import { SEO } from '../lib/components/seo';
import SvgIcon from '../lib/components/svg-icon/svg-icon';
import { Heading } from '../lib/components/typography/heading';
import { Paragraph } from '../lib/components/typography/paragraph';
import { BaseLayout } from '../lib/ui/base-layout';
import { RichText } from '../lib/ui/rich-text';
import { quickLinks } from '../staticData/contact';

const Contact = () => {
    const {
        pageContent,
        contactUs,
        globalBrandText,
        globalBrandReq,
        localDealerText,
        localDealerReq,
        careersText,
        careersReq,
        careersImages,
        contactInfo
    } = useStaticQuery(query);

    const content = {
        contactUs: {
            text: contactUs.content[0],
            images: contactUs.images[0]
        },
        globalBrand: {
            text: globalBrandText,
            req: globalBrandReq
        },
        localDealer: {
            text: localDealerText,
            req: localDealerReq
        },
        careers: {
            text: careersText,
            req: careersReq,
            images: careersImages.images[0]
        },
        contactInfo: contactInfo
    };

    return (
        <>
            <SEO
                title={pageContent.seo.title}
                description={pageContent.seo.description}
                image={pageContent.seo.image.file.url}
            />
            <BaseLayout>
                <Section className="bg-light-gray rounded-t-md">
                    <Container>
                        <Grid
                            container
                            base={false}
                            className={clsx(
                                't-lg:items-center t-lg:grid-cols-2 t-lg:gap-x-10',
                                'l-sm:grid-cols-12 l-sm:gap-x-2 l-sm:items-stretch'
                            )}>
                            <Grid
                                item
                                className={clsx(
                                    'l-sm:col-span-4 l-sm:py-7',
                                    'd-md:col-span-3'
                                )}>
                                <Heading tagName="h1">
                                    {content.contactUs.text.title}
                                </Heading>
                                <RichText
                                    data={content.contactUs.text.richText}
                                />

                                <ul className="mt-br">
                                    {content.contactInfo.phoneNumbers.map(
                                        (item: string, i: number) => {
                                            return (
                                                <li
                                                    key={`phone-number-${i}`}
                                                    className="leading-loose">
                                                    <a
                                                        href={`tel:${item}`}
                                                        className="text-opacity-80 text-dark-gray">
                                                        {item}
                                                    </a>
                                                </li>
                                            );
                                        }
                                    )}
                                </ul>
                            </Grid>

                            <Grid
                                item
                                container
                                base={false}
                                defaultGap={false}
                                className={clsx(
                                    'l-sm:col-span-6-13 l-sm:grid-cols-2 l-sm:items-center',
                                    'd-md:col-span-5-13'
                                )}>
                                <Grid
                                    item
                                    className={clsx('l-sm:h-full l-sm:-mr-10')}>
                                    <Image
                                        className={clsx(
                                            'h-54 min-h-30',
                                            'l-sm:h-full',
                                            'transform -scale-x-1'
                                        )}
                                        src={content.contactUs.images.file.url}
                                        alt={
                                            content.contactUs.images.description
                                        }
                                    />
                                </Grid>

                                <Grid
                                    item
                                    className={clsx(
                                        'relative z-10 p-7 max-w-9/10 mx-auto -mt-5 bg-white rounded-md',
                                        't-sm:-mt-10',
                                        'l-sm:mt-0 l-sm:max-w-none l-sm:-ml-10'
                                    )}>
                                    <Paragraph
                                        body={false}
                                        className="font-medium">
                                        Select any of the quick links below for
                                        details on your specific request.
                                    </Paragraph>

                                    <ul className="mt-4">
                                        {quickLinks.map((item, i) => {
                                            return (
                                                <li
                                                    className="mb-1 last:mb-0"
                                                    key={`quick-links-${i}`}>
                                                    <AnchorLink
                                                        key={`quick-link-${i}`}
                                                        anchor={item.anchor}
                                                        className={clsx(
                                                            'grid grid-cols-auto-1fr items-center text-secondary',
                                                            'transition-transform duration-300 ease-out transform hover:translate-x-1.5'
                                                        )}>
                                                        <SvgIcon
                                                            icon="arrow-right-circle"
                                                            className="fill-current"
                                                            size={20}
                                                        />
                                                        <span className="ml-2 text-dark-gray text-opacity-80">
                                                            {item.title}
                                                        </span>
                                                    </AnchorLink>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Section>

                <Section id="global-brand-partner">
                    <Container>
                        <Grid
                            container
                            base={false}
                            className={clsx(
                                't-lg:grid-cols-2 t-lg:gap-x-10',
                                'l-sm:grid-cols-12 l-sm:gap-x-2'
                            )}>
                            <Grid item className={clsx('l-sm:col-span-2-6')}>
                                <Heading>
                                    {content.globalBrand.text.title}
                                </Heading>
                                <RichText
                                    data={content.globalBrand.text.richText}
                                />
                            </Grid>

                            <Grid
                                item
                                className={clsx(
                                    'bg-light-gray p-7 rounded-md',
                                    'l-sm:col-span-7-12'
                                )}>
                                <RichText
                                    listClassName="mt-br"
                                    data={content.globalBrand.req.richText}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </Section>

                <Section framed className="bg-primary" id="local-dealer">
                    <Container>
                        <Grid
                            container
                            base={false}
                            className={clsx(
                                't-lg:grid-cols-2 t-lg:gap-x-10',
                                'l-sm:grid-cols-12 l-sm:gap-x-2'
                            )}>
                            <Grid item className={clsx('l-sm:col-span-2-6')}>
                                <Heading color="white">
                                    {content.localDealer.text.title}
                                </Heading>
                                <RichText
                                    color="white"
                                    data={content.localDealer.text.richText}
                                />
                            </Grid>

                            <Grid
                                item
                                className={clsx(
                                    'bg-primary-100 p-7 rounded-md',
                                    'l-sm:col-span-7-12'
                                )}>
                                <RichText
                                    color="white"
                                    listClassName="mt-br"
                                    data={content.localDealer.req.richText}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </Section>

                <Section id="careers">
                    <Container>
                        <Grid
                            container
                            base={false}
                            className={clsx(
                                't-lg:grid-cols-2 t-lg:gap-x-10',
                                'l-sm:grid-cols-12 l-sm:gap-x-2'
                            )}>
                            <Grid
                                item
                                className={clsx(
                                    't-lg:py-7',
                                    'l-sm:col-span-1-5',
                                    'd-md:col-span-1-4'
                                )}>
                                <Heading>{content.careers.text.title}</Heading>
                                <RichText
                                    data={content.careers.text.richText}
                                />

                                <RichText
                                    className="mt-5"
                                    listClassName="mt-br"
                                    data={content.careers.req.richText}
                                />
                            </Grid>

                            <Grid
                                item
                                className={clsx(
                                    'l-sm:col-span-6-13',
                                    'd-md:col-span-5-13'
                                )}>
                                <Image
                                    className={clsx(
                                        'h-54 min-h-30',
                                        't-lg:h-full'
                                    )}
                                    src={content.careers.images.file.url}
                                    alt={content.careers.images.description}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </Section>
            </BaseLayout>
        </>
    );
};

export default Contact;

const query = graphql`
    {
        pageContent: contentfulPages(slug: { eq: "/contact" }) {
            seo {
                title
                description
                image {
                    file {
                        url
                    }
                }
            }
        }
        contactUs: contentfulComponentSection(
            identifier: { eq: "contact-front" }
        ) {
            content {
                ... on ContentfulComponentText {
                    id
                    title
                    richText {
                        raw
                    }
                }
            }
            images {
                file {
                    url
                }
                description
            }
        }
        globalBrandText: contentfulComponentText(
            identifier: { eq: "be-a-global-brand-partner" }
        ) {
            title
            richText {
                raw
            }
        }
        globalBrandReq: contentfulComponentText(
            identifier: { eq: "req-global-brand-partner" }
        ) {
            title
            richText {
                raw
            }
        }
        localDealerText: contentfulComponentText(
            identifier: { eq: "local-dealer" }
        ) {
            title
            richText {
                raw
            }
        }
        localDealerReq: contentfulComponentText(
            identifier: { eq: "req-local-dealer" }
        ) {
            title
            richText {
                raw
            }
        }
        careersText: contentfulComponentText(identifier: { eq: "careers" }) {
            title
            richText {
                raw
            }
        }
        careersReq: contentfulComponentText(identifier: { eq: "req-careers" }) {
            title
            richText {
                raw
            }
        }
        careersImages: contentfulComponentSection(
            identifier: { eq: "contact-careers-section" }
        ) {
            images {
                file {
                    url
                }
                description
            }
        }
        contactInfo: contentfulContactInformation {
            phoneNumbers
        }
    }
`;

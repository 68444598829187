export const quickLinks = [
    {
        anchor: 'product-demo',
        title: 'Product demo'
    },
    {
        anchor: 'global-brand-partner',
        title: 'Be a global brand partner'
    },
    {
        anchor: 'local-dealer',
        title: 'Be a local dealer'
    },
    {
        anchor: 'careers',
        title: 'Careers'
    }
];
